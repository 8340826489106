import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'

// Components
import MainHeader from '../Layout/MainHeader'
import Footer from '../Layout/Footer'

// Images
import cloud from '../../Images/Techigent/HyperconvergedInfrastructure.jpg'
import database from '../../Images/storage.png'
import business from '../../Images/Techigent/BusinessContinuityPlanning(BCP).jpg'
import FooterMob from '../Layout/FooterMob'
import software from '../../Images/Techigent/SoftwareDefinedDataCentres(SDDC).jpg'

const SmartDataCentres = () => {

    const navigate = useNavigate();
    useEffect(() => {
        Aos.init({ duration: 1000, delay: 90 });
    }, []);

    return (
        <>
            <div>
                <MainHeader video=" sm-carrer-img" name="Infrastructure Modernization" title="Cloud, Security, Security & more" />
                <div className="container">
                    <div>
                        <div style={{ fontSize: "16px", marginTop: "52px", borderBottom: "1px solid #D1D3D4", cursor: "pointer", paddingRight: '20px' }} className="d-flex align-items-center justify-content-between sm-scroll sm-14 sm-gap-10">
                            <div style={{ width: "280px", color: "#1587C3", borderBottom: "2px solid #1587C3", paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/infrastructure/smart-data')}><b className='sliderName'>Smart Data Centres</b></div>
                            <div style={{ width: "280px", paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/infrastructure/networking')}><b className='sliderName'>Networking</b></div>
                            <div style={{ width: "280px", paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/infrastructure/boardroom-solution')}><b className='sliderName'>Boardroom Solutions</b></div>
                            <div style={{ width: "280px", paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/infrastructure/edge-computing')}><b className='sliderName'>Edge Computing</b></div>
                        </div>
                    </div>
                    {/* 
                    <div className="row sm-pt4" style={{ padding: "120px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-12 col-md-12 col-12 ">
                            <p className="mt-3 justify-content-center" style={{   fontSize: "19px" }}>
                                Enterprises are often challenged by the complexity and fixed nature of their aging data centres. We commonly see the lack of performance capacity necessary to handle the goals of advance application scenarios and reduced energy consumption. To address these challenges, organizations are increasingly moving to Smart Data Centre solutions that deliver the agility, reliability, and efficiency that support the rapid service deployment needed to compete and win in this new era.
                            </p>
                        </div>
                    </div> 
                    */}


                    <div className="row sm-pt4" style={{ padding: "120px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={cloud} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Hyperconverged Infrastructure</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>Hyper-converged infrastructure is a software-centric architecture that tightly integrates compute, storage and virtualization resources in a single system that usually consists of x86 hardware.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px" }} data-aos="fade-left">

                        <div className="col-lg-6 col-md-6 col-12 lg-none">
                            <img src={database} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Storage and Server </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                We help customers in providing server and storage solutions as per their requirement. We have a consultative approach and rich experience in designing and delivering such solutions.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 sm-none">
                            <img src={database} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "120px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={cloud} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Back up and DR</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                Backup and disaster recovery are vital components in your business continuity plan. We are committed to providing suitable solutions that meet your business needs.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px" }} data-aos="fade-left">

                        <div className="col-lg-6 col-md-6 col-12 lg-none">
                            <img src={business} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Business Continuity Planning (BCP) </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>Digitization is intensifying and the step change is greater than the internet. Businesses have realized the need for transformation however, few realize how far the transformation needs to be. That why when it comes to maintaining continuity, you need to think beyond!
                                We provide a unique Business Service Availability Management solution from which can help CXOs make well informed decisions with reduced RTO by enabling real time visibility into financial impact of downtime of critical IT service, unplanned outage or degradation of services.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 sm-none">
                            <img src={business} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>


                    <div className="row sm-pt4" style={{ padding: "120px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={software} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Software Defined Data Centres (SDDC)</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                An SDDC is a data storage facility in which all infrastructure elements -- networking, storage, CPU and security -- are virtualized and delivered as a service. Deployment, operation, provisioning and configuration are abstracted from hardware. Those tasks are implemented through software intelligence
                            </p>
                        </div>
                    </div>

                </div>
                <div className="footer_up">
                    <div className="sm-none">
                        <Footer />
                    </div>
                    <div className="lg-none">
                        <FooterMob />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SmartDataCentres