import React, { useEffect } from 'react'
import Aos from 'aos'
import 'aos/dist/aos.css'

// Components
import Footer from './Footer'
import MainHeader from './MainHeader'
import FooterMob from '../Layout/FooterMob'
import person1 from '../../Images/person/img1.jpg'
import person4 from '../../Images/person/amit_joshi.jpeg'
import img4 from '../../Images/person/img9.JPG'
import img5 from '../../Images/person/img3.jpg'
import img6 from '../../Images/person/img4.jpg'
import img7 from '../../Images/person/img5.jpg'
import img8 from '../../Images/person/img6.jpg'
import img9 from '../../Images/person/img7.jpg'
import img10 from '../../Images/person/img8.jpg'
import Component_226_5 from '../../Images/Component_226_5.svg'


// Icons
import { FaChevronRight } from 'react-icons/fa'
import { useState } from 'react'

// Dummy Images

// Dummy array 

const LeaderTeam = () => {
    const [id, setId] = useState(0)
    useEffect(() => {
        Aos.init({ duration: 1000, delay: 90 });
    }, []);

    return (
        <>
            <div>
                <MainHeader img="c_img_team sm-carrer-img" name="Leadership Team" title="People who made us what we are" />
                <div className="container">
                    <div style={{ padding: "120px 0px" }} className="sm-pt3">
                        <div className="row d-flex justify-content-around">
                            <div className="col-lg-4" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <div className="d-flex justify-content-center sm-gap-20" style={{ marginTop: '10px' }}>
                                    <div className={id === 1 ? "leader_box_1" : "leader_box"} onClick={() => setId(1)}>
                                        <img src={person1} alt="leaders" className="leader_img " />
                                        <div className="leader_box_2">
                                            <div style={id === 1 ? { fontSize: "22px", fontWeight: 'bold', color: '#fff' } : { fontSize: "22px", fontWeight: 'bold', color: '#00155A' }} className="sm-20">Mrs. Sunita Tiwari </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div style={{ fontSize: "15px" }} >Managing Director</div>
                                                {id !== 1 &&
                                                    <FaChevronRight style={{ color: "#1F568C" }} />}
                                            </div>
                                        </div>
                                        <div className="arrow-triangle">
                                            {id === 1 &&
                                                <div className="triangle-down"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12 lg-none">
                                {id === 1 &&
                                    <>
                                        <div style={{ paddingTop: "20px", marginTop: '15px', marginLeft: '30px', marginRight: '30px' }} className="d-flex align-items-center justify-content-between sm-pt2" data-aos="fade-up">
                                            <div style={{ color: "#27187F", fontSize: "30px" }}><b className='sm-25'>Mrs. Sunita Tiwari</b></div>
                                            <img src={Component_226_5} alt="logo" className='sm-none' />
                                        </div>
                                        <div className="row mt-4 mb-4" >
                                            <div className="col-lg-12 col-md-12 col-12" data-aos="fade-up">
                                                <div style={{ color: "#00155A", fontSize: "18px", marginLeft: '30px', marginRight: '20px' }} className='sm-16'>She is a self-made businesswomen. She is focused on shaping the company's overall business agenda and leading its strategy and corporate development efforts to make Techigent Technologies Pt Ltd the most admired company. She provides timely guidance to every employee of the organization to ensure their growth.</div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            
                            <div className="col-lg-4" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <div className="d-flex justify-content-center sm-gap-20" style={{ marginTop: '10px' }}>
                                    <div className={id === 3 ? "leader_box_1" : "leader_box"} onClick={() => setId(3)}>
                                        <img src={person4} alt="leaders" className="leader_img " />
                                        <div className="leader_box_2">
                                            <div style={id === 3 ? { fontSize: "22px", fontWeight: 'bold', color: '#fff' } : { fontSize: "22px", fontWeight: 'bold', color: '#00155A' }} className="sm-20">Mr. Amit Joshi</div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div style={{ fontSize: "15px" }} >Director Technology Sales .</div>
                                                {id !== 3 &&
                                                    <FaChevronRight style={{ color: "#1F568C" }} />}
                                            </div>
                                        </div>
                                        <div className="arrow-triangle">
                                            {id === 3 &&
                                                <div className="triangle-down"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <div className="d-flex justify-content-center sm-gap-20" style={{ marginTop: '10px' }}>
                                    <div className={id === 4 ? "leader_box_1" : "leader_box"} >
                                        <img src={img4} alt="leaders" width='320px' height='350px' className="leader_img " />
                                        <div className="leader_box_2">
                                            <div style={id === 4 ? { fontSize: "22px", fontWeight: 'bold', color: '#fff' } : { fontSize: "22px", fontWeight: 'bold', color: '#00155A' }} className="sm-20">Mr. Girish Pandey </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div style={{ fontSize: "15px" }} >Head Hr amd Admin</div>
                                                {id !== 4 &&
                                                    <FaChevronRight style={{ color: "#1F568C" }} />}
                                            </div>
                                        </div>
                                        <div className="arrow-triangle">
                                            {id === 4 &&
                                                <div className="triangle-down"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 sm-none">
                                {id === 1 &&
                                    <>
                                        <div style={{ paddingTop: "20px", marginTop: '15px', marginLeft: '30px', marginRight: '30px' }} className="d-flex align-items-center justify-content-between sm-pt2" data-aos="fade-up">
                                            <div style={{ color: "#27187F", fontSize: "30px" }}><b className='sm-25'>Mrs. Sunita Tiwari</b></div>
                                            <img src={Component_226_5} alt="logo" className='sm-none' />
                                        </div>
                                        <div className="row mt-4 mb-4" >
                                            <div className="col-lg-12 col-md-12 col-12" data-aos="fade-up">
                                                <div style={{ color: "#00155A", fontSize: "18px", marginLeft: '30px', marginRight: '20px' }} className='sm-16'>She is a self-made businesswomen. She is focused on shaping the company's overall business agenda and leading its strategy and corporate development efforts to make Techigent Technologies Pt Ltd the most admired company. She provides timely guidance to every employee of the organization to ensure their growth.</div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="col-lg-12 lg-none">
                                {id === 3 &&
                                    <>
                                        <div style={{ paddingTop: "20px", marginTop: '15px', marginLeft: '30px', marginRight: '20px' }} className="d-flex align-items-center justify-content-between sm-pt2" data-aos="fade-up">
                                            <div style={{ color: "#27187F", fontSize: "30px" }}><b className='sm-25'>Mr. Amit Joshi </b></div>
                                            <img src={Component_226_5} alt="logo" className='sm-none' />
                                        </div>
                                        <div className="row mt-4 mb-4" >
                                            <div className="col-lg-12 col-md-12 col-12" data-aos="fade-up">
                                                <div style={{ color: "#00155A", fontSize: "18px", marginLeft: '30px', marginRight: '20px' }} className='sm-16'>He is MBA in marketing and has 15+ Years of experience in IT Solutions , Cybersecurity, DevSecOps and Cloud Business. He has led organisations like Lauren Information Technologies Pvt. Ltd. in this space. He has curated many projects to tailor fit the needs of various organisations that can deliver efficiency and well has built a road ahead for organisations to succeed.</div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="col-lg-12 sm-none">
                                {id === 3 &&
                                    <>
                                        <div style={{ paddingTop: "20px", marginTop: '15px', marginLeft: '30px', marginRight: '20px' }} className="d-flex align-items-center justify-content-between sm-pt2" data-aos="fade-up">
                                            <div style={{ color: "#27187F", fontSize: "30px" }}><b className='sm-25'>Mr. Amit Joshi</b></div>
                                            <img src={Component_226_5} alt="logo" className='sm-none' />
                                        </div>
                                        <div className="row mt-4 mb-4" >
                                            <div className="col-lg-12 col-md-12 col-12" data-aos="fade-up">
                                                <div style={{ color: "#00155A", fontSize: "18px", marginLeft: '30px', marginRight: '20px' }} className='sm-16'>He is MBA in marketing and has 15+ Years of experience in IT Solutions , Cybersecurity, DevSecOps and Cloud Business. He has led organisations like Lauren Information Technologies Pvt. Ltd. in this space. He has curated many projects to tailor fit the needs of various organisations that can deliver efficiency and well has built a road ahead for organisations to succeed.</div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>

                           
                            <div className="col-lg-4" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <div className="d-flex justify-content-center sm-gap-20" style={{ marginTop: '10px' }}>
                                    <div className={id === 4 ? "leader_box_1" : "leader_box"} >
                                        <img src={img5} alt="leaders" width='320px' height='350px' className="leader_img " />
                                        <div className="leader_box_2">
                                            <div style={id === 4 ? { fontSize: "22px", fontWeight: 'bold', color: '#fff' } : { fontSize: "22px", fontWeight: 'bold', color: '#00155A' }} className="sm-20">Mrs. Dhanashree Chachad  </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div style={{ fontSize: "15px" }} >Head Strategic planning and digital marketing </div>
                                                {id !== 4 &&
                                                    <FaChevronRight style={{ color: "#1F568C" }} />}
                                            </div>
                                        </div>
                                        <div className="arrow-triangle">
                                            {id === 4 &&
                                                <div className="triangle-down"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <div className="d-flex justify-content-center sm-gap-20" style={{ marginTop: '10px' }}>
                                    <div className={id === 4 ? "leader_box_1" : "leader_box"} >
                                        <img src={img6} alt="leaders" width='320px' height='350px' className="leader_img " />
                                        <div className="leader_box_2">
                                            <div style={id === 4 ? { fontSize: "22px", fontWeight: 'bold', color: '#fff' } : { fontSize: "22px", fontWeight: 'bold', color: '#00155A' }} className="sm-20">Mr. Ashish Patel </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div style={{ fontSize: "15px" }} >Head Enterprise account</div>
                                                {id !== 4 &&
                                                    <FaChevronRight style={{ color: "#1F568C" }} />}
                                            </div>
                                        </div>
                                        <div className="arrow-triangle">
                                            {id === 4 &&
                                                <div className="triangle-down"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <div className="d-flex justify-content-center sm-gap-20" style={{ marginTop: '10px' }}>
                                    <div className={id === 4 ? "leader_box_1" : "leader_box"} >
                                        <img src={img7} alt="leaders" width='320px' height='350px' className="leader_img " />
                                        <div className="leader_box_2">
                                            <div style={id === 4 ? { fontSize: "22px", fontWeight: 'bold', color: '#fff' } : { fontSize: "22px", fontWeight: 'bold', color: '#00155A' }} className="sm-20">Mr. Navin Kunder  </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div style={{ fontSize: "15px" }} >Head SMB / mid market </div>
                                                {id !== 4 &&
                                                    <FaChevronRight style={{ color: "#1F568C" }} />}
                                            </div>
                                        </div>
                                        <div className="arrow-triangle">
                                            {id === 4 &&
                                                <div className="triangle-down"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <div className="d-flex justify-content-center sm-gap-20" style={{ marginTop: '10px' }}>
                                    <div className={id === 4 ? "leader_box_1" : "leader_box"} >
                                        <img src={img8} alt="leaders" width='320px' height='350px' className="leader_img " />
                                        <div className="leader_box_2">
                                            <div style={id === 4 ? { fontSize: "22px", fontWeight: 'bold', color: '#fff' } : { fontSize: "22px", fontWeight: 'bold', color: '#00155A' }} className="sm-20">Mr. Rakesh Bhandari </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div style={{ fontSize: "15px" }} >Head Finance and accounts.</div>
                                                {id !== 4 &&
                                                    <FaChevronRight style={{ color: "#1F568C" }} />}
                                            </div>
                                        </div>
                                        <div className="arrow-triangle">
                                            {id === 4 &&
                                                <div className="triangle-down"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <div className="d-flex justify-content-center sm-gap-20" style={{ marginTop: '10px' }}>
                                    <div className={id === 4 ? "leader_box_1" : "leader_box"} >
                                        <img src={img9} alt="leaders" width='320px' height='350px' className="leader_img " />
                                        <div className="leader_box_2">
                                            <div style={id === 4 ? { fontSize: "22px", fontWeight: 'bold', color: '#fff' } : { fontSize: "22px", fontWeight: 'bold', color: '#00155A' }} className="sm-20">Mr. Pradeep Upadhyay </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div style={{ fontSize: "15px" }} >Head – warehouse Operation  </div>
                                                {id !== 4 &&
                                                    <FaChevronRight style={{ color: "#1F568C" }} />}
                                            </div>
                                        </div>
                                        <div className="arrow-triangle">
                                            {id === 4 &&
                                                <div className="triangle-down"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <div className="d-flex justify-content-center sm-gap-20" style={{ marginTop: '10px' }}>
                                    <div className={id === 4 ? "leader_box_1" : "leader_box"} >
                                        <img src={img10} alt="leaders" width='320px' height='350px' className="leader_img " />
                                        <div className="leader_box_2">
                                            <div style={id === 4 ? { fontSize: "22px", fontWeight: 'bold', color: '#fff' } : { fontSize: "22px", fontWeight: 'bold', color: '#00155A' }} className="sm-20">Mr. Amir Khan</div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div style={{ fontSize: "15px" }} >Head Purchase</div>
                                                {id !== 4 &&
                                                    <FaChevronRight style={{ color: "#1F568C" }} />}
                                            </div>
                                        </div>
                                        <div className="arrow-triangle">
                                            {id === 4 &&
                                                <div className="triangle-down"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="footer_up">
                    <div className="sm-none">
                        <Footer />
                    </div>
                    <div className="lg-none">
                        <FooterMob />
                    </div>
                </div>
            </div>
        </>
    )
}

export default LeaderTeam