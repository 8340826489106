import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

// Images
import Component_225_1 from "../../Images/Component_225_1.svg";

// Components
import Footer from "./Footer";
import MainHeader from "./MainHeader";
import FooterMob from "../Layout/FooterMob";

// Icons
import { BiLogIn } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { IoMdAlert } from "react-icons/io";

const Login = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null)
    const navigate = useNavigate()
    useEffect(() => {
        Aos.init({ duration: 1000, delay: 90 });
    }, []);

    const contactSubmit = (e) => {
        e.preventDefault();
        if (username !== '' && password !== '') {
            if (username !== 'info@techigent.in') {
                setError("Invalid username")
            } else if (password !== 'omsaigroup_techigent') {
                setError("Invalid password")
            } else {
                localStorage.setItem('admin', 'yes')
                navigate('/contactDetails')
            }
        } else {
            setError('Please enter username and password')
        }
        /* emailjs.sendForm('service_yy0481g', 'template_b54a2ke', e.target, '3Tidp3rBHayyWJqSU')
            .then((result) => {
                console.log(result.text);
                alert('Successfully sended')
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset(); */
    };


    return (
        <>
            <MainHeader
                img="c_img_career sm-carrer-img"
                name="Login"
                title="Be the part of an excellent organisation"
            />
            <div className="sm-pd-top">


                <div
                    style={{ backgroundColor: "#F2F5F8", position: "relative" }}
                    data-aos="fade-up"
                >
                    <div className="l_e_c_1 sm-none" style={{ marginLeft: '200px' }}>
                        <img src={Component_225_1} alt="Error" />
                    </div>
                    <div className="l_e_c_2 sm-none" style={{ marginRight: '200px' }}>
                        <img src={Component_225_1} alt="Error" />
                    </div>
                    <div className="container">
                        <div style={{ padding: "80px 0px", position: "relative" }}>
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ height: "100%" }}
                            >
                                <form style={{ width: "400px", }} onSubmit={contactSubmit}>
                                    <div
                                        className="d-flex flex-column align-items-center"
                                        style={{ height: "" }}
                                    >
                                        <div style={{ color: "#00155A", fontSize: "30px" }}>
                                            <b className="sm-25">Login</b>
                                        </div>
                                    </div>
                                    {error !== null &&
                                        <div className="mt-2 " style={{ color: 'red', fontSize: '15px' }}>
                                            <IoMdAlert className="mr-2" size='26px' /> {error}
                                        </div>
                                    }
                                    <div className="row mt-4">
                                        <div className="col-12 col-lg-12 mt-2">
                                            <div className="form-group">
                                                <input
                                                    className="form-control join_form"
                                                    data-aos="fade-right"
                                                    type="text"
                                                    value={username}
                                                    onChange={(e) => setUsername(e.target.value)}
                                                    placeholder="Username"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-12 mt-2">
                                            <div className="form-group">
                                                <input
                                                    className="form-control join_form"
                                                    data-aos="fade-left"
                                                    type="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    placeholder="Password"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="d-flex justify-content-center"
                                        style={{ padding: "30px 0px 50px 0px" }}
                                    >
                                        <button className="btn btn-contact" data-aos="flip-right">
                                            <div className="d-flex justify-content-center aling-items-center">
                                                <div>Login</div>
                                                <div className="ml-2">
                                                    <BiLogIn style={{ fontSize: "17px" }} />
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_up">
                <div className="sm-none">
                    <Footer />
                </div>
                <div className="lg-none">
                    <FooterMob />
                </div>
            </div>
        </>
    );
};

export default Login;
