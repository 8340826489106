import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'

// Components
import MainHeader from '../Layout/MainHeader'
import Footer from '../Layout/Footer'

// Images
import cloud from '../../Images/cloud.png'
import database from '../../Images/Techigent/CloudSecurityPostureManagement(CSPM).jpg'
import device from '../../Images/device.png'
import virtual from '../../Images/Techigent/ZeroTrustNetworkAccess(ZTNA).jpg'
import Cipher from '../../Images/Techigent/CipherTrustDataSecurityPlatform(CDP).jpg'
import backup from '../../Images/backup.png'

import FooterMob from '../Layout/FooterMob'

const CloudSecurity = () => {

    const navigate = useNavigate();
    useEffect(() => {
        Aos.init({ duration: 1000, delay: 90 });
    }, []);

    return (
        <>
            <MainHeader video=" sm-carrer-img" name="Cyber Security" title="Cloud, Security, Security & more" />
            <div>
                <div className="container">
                    <div>
                        <div style={{ fontSize: "16px", paddingRight: '20px', marginTop: "52px", borderBottom: "1px solid #D1D3D4", cursor: "pointer" }} className="d-flex align-items-center justify-content-between sm-scroll sm-14 sm-gap-10">
                            <span style={{ paddingRight: '20px', paddingBottom: "20px" }} onClick={() => navigate('/cyberSecurity/network-perimeter')}><b>Network and Perimeter Security</b></span>
                            <span className='mt-4' style={{ color: "#1587C3", paddingRight: '20px', borderBottom: "2px solid #1587C3", paddingBottom: "40px" }} onClick={() => navigate('/cyberSecurity/cloud-security')}><b>Cloud Security</b></span>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/application-security')}><b>Application Security</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/data-security')}><b>Data Security</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/mobile-security')}><b>Mobile Security</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/endPoint-security')}><b>End Point Security</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/operation-security')}><b>Security Operations</b></div>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "120px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={cloud} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Cloud Firewall (NGFW) </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                Protects public cloud networks from attacks through features like Firewall, URL/App Filtering, IPS, Antibot, AntiVirus, VPN, Sandboxing etc
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4 sm-none" style={{ padding: "50px 0px" }} data-aos="fade-left">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Cloud Proxy </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                A cloud proxy is a cloud-based system that sits between a client and a web server, SaaS application, or data center.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={database} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>


                    <div className="row sm-pt4 lg-none" style={{ padding: "50px 0px" }} data-aos="fade-left">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={database} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Cloud Proxy </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                A cloud proxy is a cloud-based system that sits between a client and a web server, SaaS application, or data center.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={cloud} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Cloud Security Posture Management (CSPM) </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                Empower companies to identify and remediate risks through security assessments and automated compliance monitoring.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4 sm-none" style={{ padding: "50px 0px 120px 0px" }} data-aos="fade-left">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Container & Serverless Security </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                Container Security protects containerized applications from potential risk using a combination of security tools and policies. Serverless security focuses on ensuring code integrity, tight permissions and behavioural analysis.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={device} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>

                    <div className="row sm-pt4 lg-none" style={{ padding: "50px 0px 120px 0px" }} data-aos="fade-left">

                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={device} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Container & Serverless Security </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                Container Security protects containerized applications from potential risk using a combination of security tools and policies. Serverless security focuses on ensuring code integrity, tight permissions and behavioural analysis.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={virtual} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Zero Trust Network Access (ZTNA)</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                This is a set of technologies and functionalities that enable secure access to internal applications for remote users.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4 sm-none" style={{ padding: "50px 0px 120px 0px" }} data-aos="fade-left">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Secure Access Secure Edge (SASE) </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                It is the convergence of wide area networking, or WAN, and network security services like CASB, FWaaS and Zero Trust, into a single, cloud-delivered service mode.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={backup} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>

                    <div className="row sm-pt4 lg-none" style={{ padding: "50px 0px 120px 0px" }} data-aos="fade-left">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={backup} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Secure Access Secure Edge (SASE) </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                It is the convergence of wide area networking, or WAN, and network security services like CASB, FWaaS and Zero Trust, into a single, cloud-delivered service mode.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={Cipher} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Cipher Trust Data Security Platform (CDP) </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                It is an integrated suite of data-centric security products and solutions that unify data discovery, protection and control in one platform.
                            </p>
                        </div>
                    </div>

                </div>
                <div className="footer_up">
                    <div className="sm-none">
                        <Footer />
                    </div>
                    <div className="lg-none">
                        <FooterMob />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CloudSecurity