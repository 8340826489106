import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'

// Components
import MainHeader from '../Layout/MainHeader'
import Footer from '../Layout/Footer'

// Images
import cloud from '../../Images/Techigent/WebApplicationFirewall.jpg'
import database from '../../Images/Techigent/DistributedDenialofService(DDOS).jpg'
import security from '../../Images/Techigent/SecureAccessSecureEdge.jpg'
import device from '../../Images/device.png'
import virtual from '../../Images/Techigent/APIManagement.jpg'
import FooterMob from '../Layout/FooterMob'

const ApplicationSecurity = () => {

    const navigate = useNavigate();
    useEffect(() => {
        Aos.init({ duration: 1000, delay: 90 });
    }, []);

    return (
        <>
            <div>
                <MainHeader video="sm-carrer-img" name="Cyber Security" title="Cloud, Security, Security & more" />
                <div className="container">
                    <div>
                        <div style={{ fontSize: "16px", marginTop: "52px", paddingRight: '20px', borderBottom: "1px solid #D1D3D4", cursor: "pointer" }} className="d-flex align-items-center justify-content-between sm-scroll sm-14 sm-gap-10">
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/network-perimeter')}><b>Network and Perimeter Security</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/cloud-security')}><b>Cloud Security</b></div>
                            <div className='mt-4' style={{ color: "#1587C3", paddingRight: '20px', borderBottom: "2px solid #1587C3", paddingBottom: "40px" }} onClick={() => navigate('/cyberSecurity/application-security')}><b>Application Security</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/data-security')}><b>Data Security</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/mobile-security')}><b>Mobile Security</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/endPoint-security')}><b>End Point Security</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/operation-security')}><b>Security Operations</b></div>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={cloud} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Web Application Firewall (WAF) </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                A WAF or web application firewall helps protect web applications by filtering and monitoring HTTP traffic between a web application and the Internet.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4 sm-none" style={{ padding: "50px 0px" }} data-aos="fade-left">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Distributed Denial of Services (DDOS)  </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                A distributed denial-of-service (DDoS) attack is a malicious attempt to disrupt the normal traffic of a targeted server, service or network by overwhelming the target or its surrounding infrastructure with a flood of Internet traffic.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={database} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>

                    <div className="row sm-pt4 lg-none" style={{ padding: "50px 0px" }} data-aos="fade-left">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={database} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Distributed Denial of Services (DDOS)  </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                A distributed denial-of-service (DDoS) attack is a malicious attempt to disrupt the normal traffic of a targeted server, service or network by overwhelming the target or its surrounding infrastructure with a flood of Internet traffic.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={security} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Static Application Security Testing (SAST) </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                Static Code Analyzer (SCA) uses multiple algorithms and an expansive knowledge base of secure coding rules to analyse an application's source code for exploitable vulnerabilities.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4 sm-none" style={{ padding: "50px 0px 120px 0px" }} data-aos="fade-left">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Dynamic Application Security Testing (DAST)  </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                A dynamic application security testing (DAST) tool that identifies application vulnerabilities in deployed web applications and services.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={device} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>

                    <div className="row sm-pt4 lg-none" style={{ padding: "50px 0px 120px 0px" }} data-aos="fade-left">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={device} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Dynamic Application Security Testing (DAST)  </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                A dynamic application security testing (DAST) tool that identifies application vulnerabilities in deployed web applications and services.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={virtual} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Application Programming Interface (API) Security </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                API security involves the implementation of strategies and procedures to mitigate the vulnerabilities and security threats of the API (Application Programming Interface).
                            </p>
                        </div>
                    </div>

                </div>
                <div className="footer_up">
                    <div className="sm-none">
                        <Footer />
                    </div>
                    <div className="lg-none">
                        <FooterMob />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ApplicationSecurity