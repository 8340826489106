import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'

// Components
import MainHeader from '../Layout/MainHeader'
import Footer from '../Layout/Footer'

// Images
import cloud from '../../Images/Techigent/VideoConferencing.jpg'
import database from '../../Images/Techigent/InteractivePanels.jpg'
import Displays from '../../Images/Techigent/LargeFormatDisplays.jpg'
import Video from '../../Images/Techigent/VideoWalls.jpg'
import Meetings from '../../Images/Techigent/MeetingsSolutions.jpg'
import FooterMob from '../Layout/FooterMob'

const BoardroomSolutions = () => {

    const navigate = useNavigate();
    useEffect(() => {
        Aos.init({ duration: 1000, delay: 90 });
    }, []);

    return (
        <>
            <div>
                <MainHeader video=" sm-carrer-img" name="Infrastructure Modernization" title="Cloud, Security, Security & more" />
                <div className="container">
                    <div>
                        <div style={{ fontSize: "16px", paddingRight: '20px', marginTop: "52px", borderBottom: "1px solid #D1D3D4", cursor: "pointer" }} className="d-flex align-items-center justify-content-between sm-scroll sm-14 sm-gap-10">
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/infrastructure/smart-data')}><b>Smart Data Centres</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/infrastructure/networking')}><b>Networking</b></div>
                            <div style={{ color: "#1587C3", paddingRight: '20px', borderBottom: "2px solid #1587C3", paddingBottom: "40px" }} className='mt-4' onClick={() => navigate('/infrastructure/boardroom-solution')}><b>Boardroom Solutions</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/infrastructure/edge-computing')}><b>Edge Computing</b></div>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "120px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={cloud} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Video Conferencing</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                We provide complete room solutions that allow participants to quickly kick off and run a video conference meeting. Browse video conferencing products including conference cameras, room solutions, webcams, headsets, collaboration tools, and accessories.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4 sm-none" style={{ padding: "50px 0px" }} data-aos="fade-left">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Interactive Panels </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                Interactive flat panel displays are collaboration solutions designed for interactive whiteboarding, videoconferencing, screen sharing, and more. The latest technologies let you securely connect to participants whether they're in the room or joining remotely.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={database} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>


                    <div className="row sm-pt4 lg-none" style={{ padding: "50px 0px" }} data-aos="fade-left">

                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={database} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Interactive Panels </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                Interactive flat panel displays are collaboration solutions designed for interactive whiteboarding, videoconferencing, screen sharing, and more. The latest technologies let you securely connect to participants whether they're in the room or joining remotely.
                            </p>
                        </div>
                    </div>


                    <div className="row sm-pt4" style={{ padding: "120px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={Displays} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Large Format Displays </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                A Large Format Display (LFD) is used for commercial purposes to display eye-catching and dynamic digital signage content like advertising, information, and real-time messages in retail shops, hospitality, corporate, and public environments to attract and engage with customers and visitors.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4 sm-none" style={{ padding: "50px 0px" }} data-aos="fade-left">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Video Walls </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                A video wall is a large visualization surface consisting of multiple displays. Originally, they consisted of multiple televisions or monitors that were put closely together. The objective was to make it seem as one large display surface.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={Video} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>

                    <div className="row sm-pt4 lg-none" style={{ padding: "50px 0px" }} data-aos="fade-left">

                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={Video} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Video Walls </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                A video wall is a large visualization surface consisting of multiple displays. Originally, they consisted of multiple televisions or monitors that were put closely together. The objective was to make it seem as one large display surface.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "120px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={Meetings} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Meetings Solutions </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                Meeting solutions are real-time collaboration applications and associated endpoints that support interactions over a network between participants for team work, presentations, training and webinars.
                            </p>
                        </div>
                    </div>

                </div>
                <div className="footer_up">
                    <div className="sm-none">
                        <Footer />
                    </div>
                    <div className="lg-none">
                        <FooterMob />
                    </div>
                </div>
            </div>
        </>
    )
}

export default BoardroomSolutions