import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

// Images
import Component_226_5 from "../../Images/Component_226_5.svg";

// Components
import MainHeader from "../Layout/MainHeader";
import Footer from "../Layout/Footer";
import FooterMob from "../Layout/FooterMob";
import caseStudies from "../../Images/solutionsImg/caseStudies.png";
import { useNavigate } from "react-router-dom";
import Component_225_1 from "../../Images/Component_225_1.svg";

// Dummy Images

const CaseStudy = () => {
  const navigate = useNavigate()
  useEffect(() => {
    Aos.init({ duration: 1000, delay: 90 });
  }, []);
  return (
    <>
      <MainHeader
        img="c_img_case sm-carrer-img"
        name="Case Studies"
        title="We delivered what we committed"
      />
      <div style={{ marginTop: '50px', marginBottom: '50px' }}>
        <div
          className="container"
          style={{ paddingBottom: "20px", position: "relative" }}
        >
          <img
            src={Component_226_5}
            alt="logo"
            className="car_effect_03 sm-none"
          />

          <div
            className="d-flex align-items-center justify-content-between mb-4"
            data-aos="fade-right"
          >
            <div>
              <div style={{ color: "#111111" }} className="thirty">
                <b className="sm-25">Case Studies</b>
              </div>
              <div style={{ color: "#555554", fontSize: "18px" }}>
                We Delivered what we comitted
              </div>
            </div>
          </div>
        </div>

        <div className="row ml-4 mb-4 sm-none" style={{ paddingLeft: '120px', paddingBottom: '150px' }} data-aos="fade-right">

          <div className="col-lg-4 col-md-6 col-12">
            <img src={caseStudies} alt="error" style={{
              boxShadow: '0px 10px 30px #1587c317', borderRadius: '10px'
            }} width="353px" height="235px" className='sm-carrer-img' />
          </div>
          <div className="col-lg-1 sm-none"></div>
          <div className="col-lg-6 col-md-6 col-12 ">
            <div style={{ color: "#00155A", fontSize: "32px", paddingRight: '10px' }}><b className='sm-25'>Airspan</b></div>
            <p className="mt-3" style={{ color: "#5D6186", fontSize: "16px", width: "75%" }}>
              Techigent provided Cisco products to showcase how the Cisco Smart+Connected solution works which includes next-generation network technologies for energy management, collaborative workspaces, indoor navigation applications and sign-based information.
            </p>
            <button className="btn btn-know-more" style={{ cursor: 'pointer' }} onClick={() => navigate('/case-study/details')}>Know More</button>
          </div>

          <div className="l_e_c_2 sm-none" style={{ marginRight: '50px' }}>
            <img src={Component_225_1} alt="Error" />
          </div>
        </div>
        <div className="row mb-4 lg-none" style={{ paddingLeft: '10px', }} data-aos="fade-right">
          <div className="col-lg-4 col-md-6 col-12">
            <img src={caseStudies} alt="error" style={{
              boxShadow: '0px 10px 30px #1587c317', borderRadius: '10px'
            }} width="353px" height="235px" className='sm-carrer-img' />
          </div>
          <div className="col-lg-1 sm-none"></div>
          <div className="col-lg-6 col-md-6 col-12 ">
            <div style={{ color: "#00155A", fontSize: "32px", paddingRight: '10px' }}><b className='sm-25'>Airspan</b></div>
            <p className="mt-3" style={{ color: "#5D6186", fontSize: "16px", width: "75%" }}>
              Techigent provided Cisco products to showcase how the Cisco Smart+Connected solution works which includes next-generation network technologies for energy management, collaborative workspaces, indoor navigation applications and sign-based information.
            </p>
            <button className="btn btn-know-more" style={{ cursor: 'pointer' }} onClick={() => navigate('/case-study/details')}>Know More</button>
          </div>
        </div>
      </div>


      <div className="footer_up">
        <div className="sm-none">
          <Footer />
        </div>
        <div className="lg-none">
          <FooterMob />
        </div>
      </div>
    </>
  );
};

export default CaseStudy;
