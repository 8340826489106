import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

// Images
import Component_225_1 from "../../Images/Component_225_1.svg";

// Components
import Footer from "./Footer";
import MainHeader from "./MainHeader";
import Component_226_5 from "../../Images/Component_226_5.svg";
import FooterMob from "../Layout/FooterMob";
import emailjs from '@emailjs/browser';

// Icons
import { FaTelegramPlane } from "react-icons/fa";
import { BiChevronDown, BiCurrentLocation, BiLinkAlt } from "react-icons/bi"; import {
    ref,
    uploadBytes,
    getDownloadURL,
    listAll,
    list,
} from "firebase/storage";
import { v4 } from "uuid";
import { storage } from "../../firebase";
import axios from "axios";
import { useParams } from "react-router-dom";

const JobDetails = () => {
    const [imageUpload, setImageUpload] = useState(null);
    const [imageUrls, setImageUrls] = useState([]);
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()
    const [message, setMessage] = useState()
    const [error, setError] = useState()
    const { id } = useParams()
    const [chooseBtn, setChooseBtn] = useState(false)
    useEffect(() => {
        Aos.init({ duration: 1000, delay: 90 });
        if (id !== null) {
            getData(id)
        }
    }, []);

    const [data, setData] = useState([])
    const getData = (id) => {
        axios.get(`https://api-techigent.vercel.app/vacancy/${id}`)
            .then((response) => {
                setData(response.data)
                console.log(response.data)
            })
            .catch((error) => {
                alert(error.message)
            })
    }
    const uploadFile = (e) => {
        e.preventDefault()
        if (imageUpload == null) { setError('Resume Not Selected'); return }
        else {
            const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);
            uploadBytes(imageRef, imageUpload).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    contactCreate(e, url)
                    contactSubmit(e, url)
                }).catch((error) => {
                    alert(error.message)
                });
            });
        }
    };

    const contactCreate = (e, url) => {
        e.preventDefault()
        axios.post('https://api-techigent.vercel.app/newCandidate/create', {
            Name: name,
            email: email,
            mobile: phone,
            department: data.Department,
            degination: data.Degination,
            location: data.location,
            message: message,
            Status: '1',
            resume: url,
            companyStatus: 1
        })
            .then((response) => {
                console.log(response);
            }, (error) => {
                console.log(error);
            });
        e.target.reset();
    }
    const contactSubmit = (e, url) => {
        e.preventDefault();
        contactCreate(url)
        emailjs.sendForm('service_zv75tqd', 'template_b54a2ke', e.target, '3Tidp3rBHayyWJqSU')
            .then((result) => {
                console.log(result.text);
                alert('Successfully sended')
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <>
            <div style={{ paddingTop: "90px" }} className="sm-pd-top">
                <div className="container" style={{ position: "relative" }}>
                    <img
                        src={Component_226_5}
                        alt="logo"
                        className="car_effect_03 sm-none"
                    />
                    <div
                        style={{
                            color: "#00155A",
                            position: "relative",
                            paddingTop: "10px",
                        }}
                        className="thirty"
                        data-aos="fade-up"
                    >
                        <div>
                            <b className="sm-25">Job Details</b>
                        </div>
                    </div>
                    <div className="career_box_active_job d-flex justify-items-end flex-column mb-1"
                        style={{ marginTop: "50px", padding: '20px' }} data-aos="fade-up">
                        <div className="d-flex justify-content-around sm-none">
                            <div>
                                <div style={{ fontSize: "24px" }}>
                                    <b >Department</b> -  {data?.Department}
                                </div>

                                <div className="mt-4 mb-4" style={{ fontSize: "18px" }}>
                                    <span className="mr-3" style={{ borderRight: '1px solid black', paddingRight: '10px' }}>{data?.noofvacancy} Opening</span>
                                    <BiCurrentLocation className="mb-1 mr-2" style={{ fontSize: "18px" }} />
                                    <span style={{ fontSize: "18px" }}>{data?.location}</span>
                                </div>

                            </div>
                            <div>

                                <div
                                    className="d-flex align-items-end"
                                    style={{ gap: "5px", color: "#555554" }}
                                >
                                    <div style={{ fontSize: "24px" }}>
                                        <b>Degination</b> - {data.Degination}
                                    </div>
                                </div>

                            </div>


                        </div>
                        <div className="lg-none">
                            <div>
                                <div style={{ fontSize: "18px" }}>
                                    <b >Department</b> <br /> {data?.Department}
                                </div>

                                <div className="mt-4" style={{ fontSize: "18px" }}>
                                    <b>Degination</b> <br /> {data.Degination}
                                </div>
                                <div className="mt-4" style={{ fontSize: "16px" }}>
                                    <span className="mr-3" style={{ paddingRight: '10px' }}>{data?.noofvacancy} Opening</span>
                                </div>
                                <div className="mt-2" style={{ fontSize: "16px" }}>
                                    <BiCurrentLocation className="mb-1 mr-2" style={{ fontSize: "18px" }} />
                                    <span style={{ fontSize: "18px" }}>{data?.location}</span>
                                </div>
                            </div>
                        </div>

                        <div className="sm-none" style={{ marginLeft: '11%', marginRight: '9%', fontSize: "24px", }}>
                            <b>Job Description</b>
                            <div className="mt-4" style={{ backgroundColor: '#fff', padding: '20px', fontSize: '15px', borderRadius: '15px' }}>
                                {data?.jobDes}
                            </div>
                        </div>
                        <div className="lg-none mt-2" style={{ fontSize: "18px", }}>
                            <b>Job Description</b>
                            <div className="mt-4" style={{ backgroundColor: '#fff', padding: '20px', fontSize: '15px', borderRadius: '15px' }}>
                                {data?.jobDes}
                            </div>
                        </div>
                        {!chooseBtn &&
                            <div className="d-flex justify-content-center">
                                <button className="btn btn_apply" onClick={() => setChooseBtn(true)}>Apply Now</button>
                            </div>
                        }
                    </div>
                    {chooseBtn &&
                        <div
                            style={{ backgroundColor: "#F2F5F8", position: "relative" }}
                            data-aos="fade-up"
                        >
                            <div className="l_e_c_1 sm-none">
                                <img src={Component_225_1} alt="Error" />
                            </div>
                            <div className="l_e_c_2 sm-none">
                                <img src={Component_225_1} alt="Error" />
                            </div>
                            <div className="container">
                                <div style={{ padding: "80px 0px", position: "relative" }}>
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{ height: "100%" }}
                                    >
                                        <form style={{ width: "90%" }} onSubmit={uploadFile}>
                                            <div
                                                className="d-flex flex-column align-items-center"
                                                style={{ height: "" }}
                                            >
                                                <div style={{ color: "#00155A", fontSize: "30px" }}>
                                                    <b className="sm-25">Apply Now</b>
                                                </div>

                                                <div className="join-1">
                                                    Fill the form
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-12 col-lg-12 mt-4">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control join_form"
                                                            data-aos="fade-right"
                                                            type="text"
                                                            placeholder="Your full name"
                                                            value={name}
                                                            onChange={(e) => setName(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-6 mt-4">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control join_form"
                                                            data-aos="fade-right"
                                                            type="text"
                                                            placeholder="Your email address"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-6 mt-4">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control join_form"
                                                            data-aos="fade-left"
                                                            type="text"
                                                            placeholder="Your mobile number"
                                                            value={phone}
                                                            onChange={(e) => setPhone(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-12 mt-4">
                                                    <div className="form-group">
                                                        <textarea
                                                            rows="4"
                                                            className="form-control join_form"
                                                            data-aos="fade-up"
                                                            type="text"
                                                            placeholder="Type your message here."
                                                            value={message}
                                                            onChange={(e) => setMessage(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="d-flex align-items-center"
                                                style={{ gap: "7px" }}
                                            >
                                                <BiLinkAlt style={{ fontSize: "22px" }} />
                                                <div className="mt-1" style={{ fontSize: "16px", color: "#00155A" }} >
                                                    <label htmlFor="file1" style={{ cursor: 'pointer', }} >
                                                        {imageUpload ? 'Resume Selected' : 'Attach Resume, Cover Letter etc'}
                                                    </label>
                                                    <input type="file" id="file1" style={{ visibility: 'hidden' }} onChange={(event) => {
                                                        setImageUpload(event.target.files[0]);
                                                    }} />
                                                </div>
                                            </div>
                                            <div
                                                className="d-flex justify-content-center"
                                                style={{ padding: "22px 0px 50px 0px" }}
                                            >
                                                <button className="btn btn-contact" data-aos="flip-right">
                                                    <div className="d-flex justify-content-center aling-items-center">
                                                        <div>Submit</div>
                                                        <div className="ml-2">
                                                            <FaTelegramPlane style={{ fontSize: "17px" }} />
                                                        </div>
                                                    </div>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    }

                </div>


            </div>
            <div className="footer_up">
                <div className="sm-none">
                    <Footer />
                </div>
                <div className="lg-none">
                    <FooterMob />
                </div>
            </div>
        </>
    );
};

export default JobDetails;
