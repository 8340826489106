// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD2VL9ayoYozULQJgyzEkT7htJMER5TIc8",
    authDomain: "techigent-omsai.firebaseapp.com",
    projectId: "techigent-omsai",
    storageBucket: "techigent-omsai.appspot.com",
    messagingSenderId: "1080279788213",
    appId: "1:1080279788213:web:a7b542ff2c0e8ed781acfc",
    measurementId: "G-TB1NF4VCK2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const storage = getStorage(app);