import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'

// Components
import MainHeader from '../Layout/MainHeader'
import Footer from '../Layout/Footer'

// Images
import cloud from '../../Images/Techigent/EndpointProtectionPlatform(EPP).jpg'
import database from '../../Images/storage.png'
import extend from '../../Images/Techigent/ExtendedDetectionandResponse(XDR).png'
import FooterMob from '../Layout/FooterMob'

const EndPointSecurity = () => {

    const navigate = useNavigate();
    useEffect(() => {
        Aos.init({ duration: 1000, delay: 90 });
    }, []);

    return (
        <>
            <div>
                <MainHeader video=" sm-carrer-img" name="Cyber Security" title="Cloud, Security, Security & more" />
                <div className="container">
                    <div>
                        <div style={{ fontSize: "16px", paddingRight: '20px', marginTop: "52px", borderBottom: "1px solid #D1D3D4", cursor: "pointer" }} className="d-flex align-items-center justify-content-between sm-scroll sm-14 sm-gap-10">
                            <div style={{ paddingBottom: "20px", paddingRight: '20px', }} onClick={() => navigate('/cyberSecurity/network-perimeter')}><b>Network and Perimeter Security</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/cloud-security')}><b>Cloud Security</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/application-security')}><b>Application Security</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/data-security')}><b>Data Security</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/mobile-security')}><b>Mobile Security</b></div>
                            <div style={{ color: "#1587C3", paddingRight: '20px', borderBottom: "2px solid #1587C3", paddingBottom: "30px" }} className='mt-2' onClick={() => navigate('/cyberSecurity/endPoint-security')}><b>End Point Security</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/operation-security')}><b>Security Operations</b></div>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "120px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={cloud} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Endpoint Protection Platform (EPP)</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                An endpoint protection platform (EPP) is a solution deployed on endpoint devices to prevent file-based malware attacks, detect malicious activity, and provide the investigation and remediation capabilities needed to respond to dynamic security incidents and alerts.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px" }} data-aos="fade-left">

                        <div className="col-lg-6 col-md-6 col-12 lg-none">
                            <img src={database} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Endpoint Detection and Response (EDR) </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                Endpoint detection and response (EDR), also known as endpoint threat detection and response (ETDR), is an integrated endpoint security solution that combines real-time continuous monitoring and collection of endpoint data with rules-based automated response and analysis capabilities.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 sm-none">
                            <img src={database} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "120px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={extend} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Extended Detection and Response (XDR)</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                XDR unifies and extends detection and response capability across. multiple security layers, providing security teams with centralized end-to-end enterprise. visibility, powerful analytics, automated response across the complete technology stack.
                            </p>
                        </div>
                    </div>

                </div>
                <div className="footer_up">
                    <div className="sm-none">
                        <Footer />
                    </div>
                    <div className="lg-none">
                        <FooterMob />
                    </div>
                </div>
            </div>
        </>
    )
}

export default EndPointSecurity