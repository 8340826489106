import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'

// Components
import Footer from '../Layout/Footer'

// Images
import FooterMob from '../Layout/FooterMob'
import axios from 'axios'

const Deshboard = () => {
    const [count, setCount] = useState(0)
    const [status, setStatus] = useState(false)
    const navigate = useNavigate();
    const admin = localStorage.getItem('admin')
    console.log(admin)
    useEffect(() => {
        if (admin === '') {
            navigate('/login')
        }
        Aos.init({ duration: 1000, delay: 90 });
        getData()
    }, []);

    const [data, setData] = useState([])
    const getData = () => {
        axios.get('https://api-techigent.vercel.app/vacancy/all')
            .then((response) => {
                setData(response.data.filter((list) => list.companyStatus === 1))
            })
            .catch((error) => {
                alert(error.message)
            })
    }
    const updateVacancy = (id, no) => {
        axios.post(`https://api-techigent.vercel.app/vacancy/update/${id}`, {
            noofvacancy: no,
        })
            .then((response) => {
                console.log(response);
                getData()
            }, (error) => {
                console.log(error);
            });
    }
    const updateStatus = (id, no) => {
        axios.post(`https://api-techigent.vercel.app/vacancy/update/${id}`, {
            Status: no,
        })
            .then((response) => {
                console.log(response);
                getData()
            }, (error) => {
                console.log(error);
            });
    }
    const vacancyDelete = (id) => {
        axios.delete(`https://api-techigent.vercel.app/vacancy/delete/${id}`)
            .then((response) => {
                console.log(response);
                getData()
            }, (error) => {
                console.log(error);
            });
    }
    return (
        <>
            <div style={{ marginTop: '100px' }}>
                <div className="container">
                    <div>
                        <div style={{ fontSize: "16px", marginTop: "52px", borderBottom: "1px solid #D1D3D4", cursor: "pointer" }} className="d-flex align-items-center justify-content-around sm-scroll sm-14 sm-gap-10">
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/contactDetails')}><b>Requested ContactUs</b></div>
                            <div style={{ paddingRight: '20px', paddingBottom: "20px" }} onClick={() => navigate('/candidate')}><b>Candidate</b></div>
                            <div style={{ color: "#1587C3", borderBottom: "2px solid #1587C3", paddingRight: '20px', paddingBottom: "20px" }} onClick={() => navigate('/deshboard')}><b>Added Vacancy</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/add-vacancy')}><b>Add New Vacancy</b></div>
                        </div>
                    </div>



                </div>
                <div className="row sm-pt4 full-scroll ml-4 mr-4" style={{ padding: "20px 0px 20px 0px" }} data-aos="fade-right">
                    <table style={{ width: '100%', padding: '5px' }}>
                        <tr style={{ border: '2px solid #1587C3', fontSize: '16px', height: '30px', alignItems: 'center', backgroundColor: '#1587C3', color: 'white' }}>
                            <th>Sr.No</th>
                            <th>Department</th>
                            <th>Degination</th>
                            <th>Description</th>
                            <th>No of vacancy</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td className=" justify-content-center aling-items-center" style={{ border: '2px solid #1587C3' }}>{index + 1}</td>
                                    <td className=" justify-content-center aling-items-center" style={{ border: '2px solid #1587C3' }}>{item.Department}</td>
                                    <td className=" justify-content-center aling-items-center" style={{ border: '2px solid #1587C3' }}>{item.Degination}</td>
                                    <td className=" justify-content-center aling-items-center" style={{ border: '2px solid #1587C3' }}>{item.jobDes}</td>
                                    <td className=" justify-content-center aling-items-center" style={{ border: '2px solid #1587C3' }}>
                                        <div className="d-flex">
                                            <button style={{ backgroundColor: '#1587C3', border: 'none', color: 'white' }} className='ml-2 mr-2' onClick={() => { updateVacancy(item._id, item.noofvacancy - 1) }}>-</button>
                                            {item.noofvacancy}
                                            <button style={{ backgroundColor: '#1587C3', border: 'none', color: 'white' }} className='ml-2 mr-2' onClick={() => updateVacancy(item._id, item.noofvacancy + 1)}>+</button>
                                        </div>
                                    </td>
                                    <td className=" justify-content-center aling-items-center" style={{ border: '2px solid #1587C3' }}>
                                        <button style={item.Status === '1' ? { backgroundColor: 'green', border: 'none', color: 'white' } : { backgroundColor: 'red', border: 'none', color: 'white' }} onClick={() => updateStatus(item._id, item.Status === '1' ? '0' : '1')}>{item.Status === '1' ? 'Active' : 'Inactive'}</button>
                                    </td>
                                    <td className=" justify-content-center aling-items-center" style={{ border: '2px solid #1587C3' }}>
                                        <button className="btn btn-delete mt-1 mb-1 mr-1 ml-1" data-aos="flip-right" onClick={() => vacancyDelete(item._id)}>
                                            <div className="d-flex justify-content-center aling-items-center">
                                                <div>Delete</div>
                                            </div>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="footer_up">
                    <div className="sm-none">
                        <Footer />
                    </div>
                    <div className="lg-none">
                        <FooterMob />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Deshboard