import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'

// Images
import mobile from '../../Images/Techigent/Monitor.jfif'
import tclient from '../../Images/tclient.png'
import laptop from '../../Images/laptop.png'
import desktop from '../../Images/desktop.png'
import tablets from '../../Images/tablet.png'

// Components
import MainHeader from '../Layout/MainHeader'
import Footer from '../Layout/Footer'
import FooterMob from '../Layout/FooterMob'

const Endpoints = () => {

  const navigate = useNavigate();

  useEffect(() => {
    Aos.init({ duration: 1000, delay: 90 });
  }, []);

  return (
    <>
      <div>
        <MainHeader video=" sm-carrer-img" name="Intelligent Devices" title="Laptop, Desktop, Tablets, Mobile & more" />
        <div className="container">
          <div>
            <div style={{ fontSize: "16px", paddingRight: '20px', marginTop: "52px", borderBottom: "1px solid #D1D3D4", cursor: "pointer" }} className="d-flex align-items-center justify-content-between sm-scroll sm-14 sm-gap-10">
              <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/product&service/software-solutions')}><b>Software Solutions</b></div>
              <div style={{ color: "#1587C3", paddingRight: '20px', borderBottom: "2px solid #1587C3", paddingBottom: "20px" }} onClick={() => navigate('/product&service/endpoints')}><b>Intelligent Devices</b></div>
              <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/product&service/mobility-solutions')}><b>Mobility</b></div>
              <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/product&service/services-solutions')}><b>Services</b></div>
            </div>
          </div>

          <div className="row sm-pt4" style={{ padding: "120px 0px 50px 0px" }} data-aos="fade-right">
            <div className="col-lg-6 col-md-6 col-12 ">
              <img src={laptop} alt="error" width="100%" height="357px" className='sm-carrer-img' />
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Laptops</b></div>
              <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>We have everything for your IT Infrastructure needs ranging from computer hardware to software and solutions</p>
              <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                <ul>
                  <li>Business Laptop</li>
                  <li>Gaming Laptop</li>
                  <li>Student Laptop</li>
                  <li>Workstation Laptop</li>
                </ul>
              </p>

            </div>
          </div>

          <div className="row sm-pt1" style={{ padding: "50px 0px" }} data-aos="fade-left">

            <div className="col-lg-6 col-md-6 col-12 sm-mt3 lg-none">
              <img src={desktop} alt="error" width="100%" height="357px " className='sm-carrer-img' />
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Desktops</b></div>
              <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>We have everything for your IT Infrastructure needs ranging from computer hardware to software and solutions</p>
              <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                <ul>
                  <li>Tiny Desktop</li>
                  <li>Gaming Desktop</li>
                  <li>Business Desktop</li>
                </ul>
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-12 sm-mt3 sm-none">
              <img src={desktop} alt="error" width="100%" height="357px " className='sm-carrer-img' />
            </div>
          </div>

          <div className="row sm-pt4" style={{ padding: "120px 0px 50px 0px" }} data-aos="fade-right">
            <div className="col-lg-6 col-md-6 col-12 ">
              <img src={laptop} alt="error" width="100%" height="357px" className='sm-carrer-img' />
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Workstations</b></div>
              <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                <ul>
                  <li>Desktop Workstation</li>
                  <li>Mobile Workstation</li>
                </ul>
              </p>

            </div>
          </div>

          <div className="row sm-pt1" style={{ padding: "50px 0px 50px 0px" }} data-aos="fade-right">
            <div className="col-lg-6 col-md-6 col-12 lg-none">
              <img src={tablets} alt="error" width="100%" height="357px" className='sm-carrer-img' />
            </div>

            <div className="col-lg-6 col-md-6 col-12">
              <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Tablets and Phones</b></div>
              <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>We have everything for your IT Infrastructure needs ranging from computer hardware to software and solutions</p>
              <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                <ul>
                  <li>Android Tablets</li>
                  <li>Tablets for Business</li>
                  <li>Premium Tablets </li>
                  <li>Mobile Bundles</li>
                </ul>
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-12 sm-none">
              <img src={tablets} alt="error" width="100%" height="357px" className='sm-carrer-img' />
            </div>
          </div>


          <div className="row sm-pt4" style={{ padding: "120px 0px 50px 0px" }} data-aos="fade-right">
            <div className="col-lg-6 col-md-6 col-12 ">
              <img src={tclient} alt="error" width="100%" height="357px" className='sm-carrer-img' />
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Accessories and Software</b></div>
              <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                <ul>
                  <li>Docking Stations</li>
                  <li>Keyboard & Mice</li>
                  <li>Cases & bags</li>
                  <li>Chargers & Batteries</li>
                  <li>Audio</li>
                  <li>Stands & Mounts </li>
                  <li>Webcams </li>
                  <li>Privacy Filters </li>
                  <li>Cables & Adaptors </li>
                  <li>Stylus Pens </li>
                  <li>Graphic Cards </li>
                  <li>VR Headsets </li>
                </ul>
              </p>

            </div>
          </div>

          <div className="row sm-pt1" style={{ padding: "50px 0px 50px 0px" }} data-aos="fade-left">

            <div className="col-lg-6 col-md-6 col-12 lg-none">
              <img src={mobile} alt="error" width="100%" height="357px" className='sm-carrer-img' />
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Monitors </b></div>
              <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>We have everything for your IT Infrastructure needs ranging from computer hardware to software and solutions</p>
              <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                <ul>
                  <li>Monitors for Work</li>
                  <li>Monitors for Gaming</li>
                  <li>Monitors for Home</li>
                  <li>Docking Monitors</li>
                  <li>4K Monitors</li>
                  <li>Curved & Ultrawide Monitors  </li>
                </ul>
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-12 sm-none">
              <img src={mobile} alt="error" width="100%" height="357px" className='sm-carrer-img' />
            </div>
          </div>



        </div>
        <div className="footer_up">
          <div className="sm-none">
            <Footer />
          </div>
          <div className="lg-none">
            <FooterMob />
          </div>
        </div>
      </div>
    </>
  )
}

export default Endpoints