import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'

// Components
import MainHeader from '../Layout/MainHeader'
import Footer from '../Layout/Footer'

// Images
import cloud from '../../Images/Techigent/ITAutomation.jpg'
import database from '../../Images/Techigent/Integration.jpg'
import security from '../../Images/Techigent/Containerization.jpg'
import device from '../../Images/Techigent/Virtualization.jpg'
import api from '../../Images/Techigent/APIManagement.jpg'
import db from '../../Images/storage.png'
import os from '../../Images/Techigent/ProjectManagement.jpg'
import asset from '../../Images/Techigent/AssetManagement.jpg'
import manage from '../../Images/manage.png'
import sap from '../../Images/sap.png'
import FooterMob from '../Layout/FooterMob'

const SoftwareSol = () => {

    const navigate = useNavigate();
    useEffect(() => {
        Aos.init({ duration: 1000, delay: 90 });
    }, []);

    return (
        <>
            <div>
                <MainHeader video=" sm-carrer-img" name="Software Solutions" title="IT Automation,Integration, Containerization & more" />
                <div className="container">
                    <div>
                        <div style={{ fontSize: "16px", marginTop: "52px", borderBottom: "1px solid #D1D3D4", cursor: "pointer" }} className="d-flex align-items-center justify-content-between sm-scroll sm-14 sm-gap-10">
                            <div style={{ color: "#1587C3", borderBottom: "2px solid #1587C3", paddingRight: '20px', paddingBottom: "20px" }} onClick={() => navigate('/product&service/software-solutions')}><b>Software Solutions</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/product&service/endpoints')}><b>Intelligent Devices</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/product&service/mobility-solutions')}><b>Mobility</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/product&service/services-solutions')}><b>Services</b></div>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "120px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={cloud} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>IT Automation</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                Ansible Automation Platform provides an enterprise framework for building and operating IT automation at scale, from hybrid cloud to the edge. It enables users across an organization to create, share, and manage automation—from development and operations to security and network teams.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px" }} data-aos="fade-left">

                        <div className="col-lg-6 col-md-6 col-12 lg-none">
                            <img src={database} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Integration</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                We provide comprehensive set of integration and messaging technologies to connect applications and data across hybrid infrastructures. Integration is key when discussing business transformation—fundamental changes in how you conduct business to adapt as the market shifts—as it makes everything in IT work together. Integration not only connects, but it also adds value through the new functionalities provided by connecting different systems’ functions.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 sm-none">
                            <img src={database} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={security} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Containerization</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                Containerization has become a major trend in software development as an alternative or companion to virtualization. It involves encapsulating or packaging up software code and all its dependencies so that it can run uniformly and consistently on any infrastructure. Red Hat OpenShift Container Platform is a consistent hybrid cloud foundation for building and scaling containerized applications.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 120px 0px" }} data-aos="fade-left">

                        <div className="col-lg-6 col-md-6 col-12 lg-none">
                            <img src={device} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Virtualization</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>Virtualization uses software to create an abstraction layer over computer hardware that allows the hardware elements of a single computer—processors, memory, storage and more—to be divided into multiple VMs. Virtualization can increase IT agility, flexibility and scalability while creating significant cost savings. Greater workload mobility, increased performance and availability of resources, automated operations – they’re all benefits of virtualization that make IT simpler to manage and less costly to own and operate.</p>

                        </div>
                        <div className="col-lg-6 col-md-6 col-12 sm-none">
                            <img src={device} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={db} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Database</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                We have partnership with Oracle, Microsoft and IBM to provide database solutions
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 120px 0px" }} data-aos="fade-left">

                        <div className="col-lg-6 col-md-6 col-12 lg-none">
                            <img src={api} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>API Management</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                An API management platform is a tool used to access, distribute, control, and analyze APIs used by developers in an enterprise setting. API management platforms benefit organizations by centralizing control over their API integrations while ensuring they continuously meet high performance and security standards. IBM API connect is a secure API management solution.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 sm-none">
                            <img src={api} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>


                    <div className="row sm-pt4" style={{ padding: "50px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={os} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Project Management</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                Oracle Primavera is an enterprise project portfolio management software. It includes project management, scheduling, risk analysis, opportunity management, resource management, collaboration, and control capabilities, and integrates with other enterprise software.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 50px 0px" }} data-aos="fade-up">

                        <div className="col-lg-6 col-md-6 col-12 lg-none">
                            <img src={asset} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Asset Management</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                We provide automate the end-to-end lifecycle for software licenses, hardware assets, and cloud—on one platform.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 sm-none">
                            <img src={manage} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 120px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={sap} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Service Desk Management</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                It helps customers to manage service operations and ensure guaranteed service delivery as per defined service level goals. It enables you to implement ITIL best practices for incident, problem, change and service fulfilment processes to make the service delivery standardized and agile.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 50px 0px" }} data-aos="fade-up">

                        <div className="col-lg-6 col-md-6 col-12 lg-none">
                            <img src={database} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Patch Management</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                Patch management is the subset of systems management that involves identifying, acquiring, testing and installing patches, or code changes, that are intended to fix bugs, close security holes or add features. Patch management helps keep computers and networks secure, reliable and up to date with features and functionality that the organization considers important.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 sm-none">
                            <img src={manage} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>

                    <div className="row sm-pt4" style={{ padding: "50px 0px 120px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={sap} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Mail and Massaging </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                We consult our customer in getting right collaboration and productivity platform as per their requirement and budgets. Being a Microsoft partner, we help customers to migrate from any platform to M365.
                            </p>
                        </div>
                    </div>

                </div>
                <div className="footer_up">
                    <div className="sm-none">
                        <Footer />
                    </div>
                    <div className="lg-none">
                        <FooterMob />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SoftwareSol