import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'

// Components
import Footer from './Footer'
import MainHeader from './MainHeader'
import FooterMob from '../Layout/FooterMob'

// Images
import Component_226_5 from '../../Images/Component_226_5.svg'
import person1 from '../../Images/person/sunita.jpeg'
import person2 from '../../Images/person/shatansu.jpeg'
import person3 from '../../Images/person/mukesh.jpeg'
import person4 from '../../Images/person/amit.jpeg'

// Icons
import { FaChevronRight } from 'react-icons/fa'

// Dummy Images

// Dummy array 

const LeaderDetail = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    useEffect(() => {
        Aos.init({ duration: 1000, delay: 90 });
    }, []);
    console.log(id);
    return (
        <>
            <div>
                <MainHeader img="c_img_team sm-carrer-img" name="Leadership Team" title="People who made us what we are" />

                <div className="container">
                    <div style={{ padding: "120px 0px" }} className="sm-pt3">
                        <div className="row d-flex justify-content-around">
                            <div className="col-lg-6" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <div className="d-flex justify-content-center sm-gap-20" style={{ marginTop: '10px' }}>
                                    <div className={id === '1' ? "leader_box_1" : "leader_box"} onClick={() => navigate(`/leaderDetail/1`)}>
                                        <img src={person1} alt="leaders" width='320px' height='450px' className="leader_img " />
                                        <div className="leader_box_2">
                                            <div style={id === '1' ? { fontSize: "22px", fontWeight: 'bold', color: '#fff' } : { fontSize: "22px", fontWeight: 'bold', color: '#00155A' }} className="sm-20">Sunita Tiwari</div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div style={{ fontSize: "15px" }} >Managing Director</div>
                                                {id !== '1' &&
                                                    <FaChevronRight style={{ color: "#1F568C" }} />}
                                            </div>
                                        </div>
                                        <div className="arrow-triangle">
                                            {id === '1' &&
                                                <div className="triangle-down"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12 lg-none">
                                {id === '1' &&
                                    <>
                                        <div style={{ paddingTop: "20px", marginTop: '15px', marginLeft: '30px', marginRight: '30px' }} className="d-flex align-items-center justify-content-between sm-pt2" data-aos="fade-up">
                                            <div style={{ color: "#27187F", fontSize: "30px" }}><b className='sm-25'>Sunita Tiwari</b></div>
                                            <img src={Component_226_5} alt="logo" className='sm-none' />
                                        </div>
                                        <div className="row mt-4 mb-4" >
                                            <div className="col-lg-12 col-md-12 col-12" data-aos="fade-up">
                                                <div style={{ color: "#00155A", fontSize: "18px", marginLeft: '30px', marginRight: '20px' }} className='sm-16'>She is a self-made businesswomen. She is focused on shaping the company's overall business agenda and leading its strategy and corporate development efforts to make Techigent Technologies Pt Ltd the most admired company. She provides timely guidance to every employee of the organization to ensure their growth.</div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="col-lg-6" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <div className="d-flex justify-content-center sm-gap-20" style={{ marginTop: '10px' }}>
                                    <div className={id === '2' ? "leader_box_1" : "leader_box"} onClick={() => navigate(`/leaderDetail/2`)}>
                                        <img src={person2} alt="leaders" width='320px' height='450px' className="leader_img " />
                                        <div className="leader_box_2">
                                            <div style={id === '2' ? { fontSize: "22px", fontWeight: 'bold', color: '#fff' } : { fontSize: "22px", fontWeight: 'bold', color: '#00155A' }} className="sm-20">Shwetanshu Shekar</div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div style={{ fontSize: "15px" }} >Chief executive officer</div>
                                                {id !== '2' &&
                                                    <FaChevronRight style={{ color: "#1F568C" }} />}
                                            </div>
                                        </div>
                                        <div className="arrow-triangle">
                                            {id === '2' &&
                                                <div className="triangle-down"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 sm-none">
                                {id === '1' &&
                                    <>
                                        <div style={{ paddingTop: "20px", marginTop: '15px', marginLeft: '30px', marginRight: '30px' }} className="d-flex align-items-center justify-content-between sm-pt2" data-aos="fade-up">
                                            <div style={{ color: "#27187F", fontSize: "30px" }}><b className='sm-25'>Sunita Tiwari</b></div>
                                            <img src={Component_226_5} alt="logo" className='sm-none' />
                                        </div>
                                        <div className="row mt-4 mb-4" >
                                            <div className="col-lg-12 col-md-12 col-12" data-aos="fade-up">
                                                <div style={{ color: "#00155A", fontSize: "18px", marginLeft: '30px', marginRight: '20px' }} className='sm-16'>She is a self-made businesswomen. She is focused on shaping the company's overall business agenda and leading its strategy and corporate development efforts to make Techigent Technologies Pt Ltd the most admired company. She provides timely guidance to every employee of the organization to ensure their growth.</div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="col-lg-12">
                                {id === '2' &&
                                    <>
                                        <div style={{ paddingTop: "20px", marginTop: '15px', marginLeft: '30px', marginRight: '20px' }} className="d-flex align-items-center justify-content-between sm-pt2" data-aos="fade-up">
                                            <div style={{ color: "#27187F", fontSize: "30px" }}><b className='sm-25'>Shwetanshu Shekar</b></div>
                                            <img src={Component_226_5} alt="logo" className='sm-none' />
                                        </div>
                                        <div className="row mt-4 mb-4" >
                                            <div className="col-lg-12 col-md-12 col-12" data-aos="fade-up">
                                                <div style={{ color: "#00155A", fontSize: "18px", marginLeft: '30px', marginRight: '20px' }} className='sm-16'>With 20+ years of IT industry experience he has an expertise in Sales & Marketing. He is an Msc. in Physics. He has has been associated with the organization since its inception.</div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="col-lg-6" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <div className="d-flex justify-content-center sm-gap-20" style={{ marginTop: '10px' }}>
                                    <div className={id === '3' ? "leader_box_1" : "leader_box"} onClick={() => navigate(`/leaderDetail/3`)}>
                                        <img src={person3} alt="leaders" width='320px' height='450px' className="leader_img" />
                                        <div className="leader_box_2">
                                            <div style={id === '3' ? { fontSize: "22px", fontWeight: 'bold', color: '#fff' } : { fontSize: "22px", fontWeight: 'bold', color: '#00155A' }} className="sm-20">Mukesh Sinha</div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div style={{ fontSize: "15px" }} >Associate Director</div>
                                                {id !== '3' &&
                                                    <FaChevronRight style={{ color: "#1F568C" }} />}
                                            </div>
                                        </div>
                                        <div className="arrow-triangle">
                                            {id === '3' &&
                                                <div className="triangle-down"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 lg-none">
                                {id === '3' &&
                                    <>
                                        <div style={{ paddingTop: "20px", marginTop: '0px', marginLeft: '30px', marginRight: '30px' }} className="d-flex align-items-center justify-content-between sm-pt2" data-aos="fade-up">
                                            <div style={{ color: "#27187F", fontSize: "30px" }}><b className='sm-25'>Mukesh Sinha</b></div>
                                            <img src={Component_226_5} alt="logo" className='sm-none' />
                                        </div>
                                        <div className="row mt-4 mb-4" >
                                            <div className="col-lg-12 col-md-12 col-12" data-aos="fade-up">
                                                <div style={{ color: "#00155A", fontSize: "18px", marginLeft: '30px', marginRight: '20px' }} className='sm-16'>With 20+ years of IT industry experience he has an expertise in Sales & Marketing. He is an Msc. in Physics. He has has been associated with the organization since its inception.</div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="col-lg-6" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <div className="d-flex justify-content-center sm-gap-20" style={{ marginTop: '10px' }}>
                                    <div className={id === '4' ? "leader_box_1" : "leader_box"} onClick={() => navigate(`/leaderDetail/4`)}>
                                        <img src={person4} alt="leaders" width='320px' height='450px' className="leader_img " />
                                        <div className="leader_box_2">
                                            <div style={id === '4' ? { fontSize: "22px", fontWeight: 'bold', color: '#fff' } : { fontSize: "22px", fontWeight: 'bold', color: '#00155A' }} className="sm-20">Amit Joshi</div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div style={{ fontSize: "15px" }} >Associate Director</div>
                                                {id !== '4' &&
                                                    <FaChevronRight style={{ color: "#1F568C" }} />}
                                            </div>
                                        </div>
                                        <div className="arrow-triangle">
                                            {id === '4' &&
                                                <div className="triangle-down"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 sm-none">
                                {id === '3' &&
                                    <>
                                        <div style={{ paddingTop: "20px", marginTop: '15px', marginLeft: '30px', marginRight: '30px' }} className="d-flex align-items-center justify-content-between sm-pt2" data-aos="fade-up">
                                            <div style={{ color: "#27187F", fontSize: "30px" }}><b className='sm-25'>Mukesh Sinha</b></div>
                                            <img src={Component_226_5} alt="logo" className='sm-none' />
                                        </div>
                                        <div className="row mt-4 mb-4" >
                                            <div className="col-lg-12 col-md-12 col-12" data-aos="fade-up">
                                                <div style={{ color: "#00155A", fontSize: "18px", marginLeft: '30px', marginRight: '20px' }} className='sm-16'>With 20+ years of IT industry experience he has an expertise in Sales & Marketing. He is an Msc. in Physics. He has has been associated with the organization since its inception.</div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="col-lg-12">
                                {id === '4' &&
                                    <>
                                        <div style={{ paddingTop: "20px", marginTop: '15px', marginLeft: '30px', marginRight: '20px' }} className="d-flex align-items-center justify-content-between sm-pt2" data-aos="fade-up">
                                            <div style={{ color: "#27187F", fontSize: "30px" }}><b className='sm-25'>Amit Joshi</b></div>
                                            <img src={Component_226_5} alt="logo" className='sm-none' />
                                        </div>
                                        <div className="row mt-4 mb-4" >
                                            <div className="col-lg-12 col-md-12 col-12" data-aos="fade-up">
                                                <div style={{ color: "#00155A", fontSize: "18px", marginLeft: '30px', marginRight: '20px' }} className='sm-16'>He is MBA in marketing and has 15+ Years of experience in IT Solutions , Cybersecurity, DevSecOps and Cloud Business. He has led organisations like Lauren Information Technologies Pvt. Ltd. in this space. He has curated many projects to tailor fit the needs of various organisations that can deliver efficiency and well has built a road ahead for organisations to succeed.</div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>

                    </div>
                </div>
                <div className="footer_up">
                    <div className="sm-none">
                        <Footer />
                    </div>
                    <div className="lg-none">
                        <FooterMob />
                    </div>
                </div>
            </div>
        </>
    )
}

export default LeaderDetail