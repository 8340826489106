import React from "react";
import "@fontsource/poppins";
import welcomeVideo from '../../Images/solutionsImg/banner.mov'

const MainHeader = (props) => {
  return (
    <>
      <div>
        {props.video !== undefined &&
          <div className="carousel-inner video-container1">
            <video autoplay='true' muted loop>
              <source src={welcomeVideo} type="video/mp4" />
            </video>
            <div style={{ width: "100%", textAlign: "left", color: "#fff" }}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      style={{ height: "300px" }}
                      className="sm-carrer-img fix_index align-items-center d-flex flex-column justify-content-center"
                    >
                      <div
                        style={{ marginTop: '5%', fontFamily: 'Poppins', fontWeight: 'bolder', letterSpacing: "2.4px" }}
                        data-text={props.name}
                        className="name_effect sm-50 sm-none"
                      >
                        {props.name}
                      </div>

                      <div
                        style={{ fontFamily: 'Poppins', fontWeight: 'bolder', letterSpacing: "2.4px" }}
                        data-text={props.name}
                        className="name_effect sm-50 lg-none"
                      >
                        {props.name}
                      </div>
                      <div className="name_effect_liner sm-none">
                        {props.title}
                      </div>

                      <div style={{ marginBottom: '35%' }} className="name_effect_liner lg-none">
                        {props.title}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {props.img !== undefined &&
          <div className={props.img}>
            <div style={{ width: "100%", textAlign: "left", color: "#fff" }}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      style={{ height: "300px" }}
                      className="sm-carrer-img fix_index align-items-center d-flex flex-column justify-content-center"
                    >
                      <div
                        style={{ marginTop: '30%', fontFamily: 'Poppins', fontWeight: 'bolder', letterSpacing: "2.4px" }}
                        data-text={props.name}
                        className="name_effect sm-50 sm-none"
                      >
                        {props.name}
                      </div>

                      <div
                        style={{ fontFamily: 'Poppins', fontWeight: 'bolder', letterSpacing: "2.4px" }}
                        data-text={props.name}
                        className="name_effect sm-50 lg-none"
                      >
                        {props.name}
                      </div>
                      <div className="name_effect_liner sm-none">
                        {props.title}
                      </div>

                      <div style={{ marginBottom: '10%' }} className="name_effect_liner lg-none">
                        {props.title}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default MainHeader;
