import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'

// Components
import MainHeader from '../Layout/MainHeader'
import Footer from '../Layout/Footer'

// Images
import cloud from '../../Images/Techigent/SecurityPolicyManagement(SPM).jpg'
import database from '../../Images/Techigent/VulnerabilityAssessment(VA).jpg'
import FooterMob from '../Layout/FooterMob'

const OperationsSecurity = () => {

    const navigate = useNavigate();
    useEffect(() => {
        Aos.init({ duration: 1000, delay: 90 });
    }, []);

    return (
        <>
            <div>
                <MainHeader video=" sm-carrer-img" name="Cyber Security" title="Cloud, Security, Security & more" />
                <div className="container">
                    <div>
                        <div style={{ fontSize: "16px", paddingRight: '20px', marginTop: "52px", borderBottom: "1px solid #D1D3D4", cursor: "pointer" }} className="d-flex align-items-center justify-content-between sm-scroll sm-14 sm-gap-10">
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/network-perimeter')}><b>Network and Perimeter Security</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/cloud-security')}><b>Cloud Security</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/application-security')}><b>Application Security</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/data-security')}><b>Data Security</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/mobile-security')}><b>Mobile Security</b></div>
                            <div style={{ paddingBottom: "20px", paddingRight: '20px' }} onClick={() => navigate('/cyberSecurity/endPoint-security')}><b>End Point Security</b></div>
                            <div style={{ color: "#1587C3", paddingRight: '20px', borderBottom: "2px solid #1587C3", paddingBottom: "40px" }} className='mt-4' onClick={() => navigate('/cyberSecurity/operation-security')}><b>Security Operations</b></div>
                        </div>
                    </div>

                    <div className="row sm-pt4 " style={{ padding: "120px 0px 50px 0px" }} data-aos="fade-right">
                        <div className="col-lg-6 col-md-6 col-12">
                            <img src={cloud} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Security Policy Management (SPM)</b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                the process of identifying, implementing, and managing the rules and procedures that all individuals must follow when accessing and using an organization's IT assets and resources.
                            </p>
                        </div>
                    </div>

                    <div className="row sm-pt4 " style={{ padding: "50px 0px" }} data-aos="fade-left">

                        <div className="col-lg-6 col-md-6 col-12 lg-none">
                            <img src={database} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div style={{ color: "#00155A", fontSize: "32px" }}><b className='sm-25'>Vulnerability Assessment (VA) </b></div>
                            <p className="mt-3" style={{ fontSize: "16px", width: "75%" }}>
                                Improves visibility and control across networks, multi-vendor firewalls, SDN, hybrid, and public cloud.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 sm-none">
                            <img src={database} alt="error" width="100%" height="357px" className='sm-carrer-img' />
                        </div>
                    </div>

                </div>
                <div className="footer_up">
                    <div className="sm-none">
                        <Footer />
                    </div>
                    <div className="lg-none">
                        <FooterMob />
                    </div>
                </div>
            </div>
        </>
    )
}

export default OperationsSecurity