import React, { useEffect } from 'react'
import Aos from 'aos'
import 'aos/dist/aos.css'
import IMG2000 from "../../Images/aword/aword1.JPG";
import IMG2002 from "../../Images/aword/aword2.JPG";
import IMG2003 from "../../Images/aword/aword3.JPG";
import IMG2004 from "../../Images/aword/aword4.JPG";
import IMG2010 from "../../Images/aword/IMG_2010.JPG";
// Dummy Images

const AwardComp = () => {
    useEffect(() => {
        Aos.init({ duration: 1000, delay: 90 });
    }, []);
    return (
        <>
            <div className="awards_box sm-pt3" data-aos="fade-up">
                <div className="container ">
                    <div className="row">
                        <div className="col-lg-4 col-12 mt-4">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "320px" }} data-aos="flip-left">
                                <img src={IMG2000} style={{ borderRadius: '10px' }} alt="error" width="320px" height="320px" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 mt-4">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "320px" }} data-aos="flip-left">
                                <img src={IMG2002} style={{ borderRadius: '10px' }} alt="error" width="320px" height="320px" />
                            </div>
                        </div>

                        <div className="col-lg-4 col-12 mt-4">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "320px" }} data-aos="flip-left">
                                <img src={IMG2003} style={{ borderRadius: '10px' }} alt="error" width="320px" height="320px" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 mt-4">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "320px" }} data-aos="flip-left">
                                <img src={IMG2010} style={{ borderRadius: '10px' }} alt="error" width="320px" height="320px" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 mt-4">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "320px" }} data-aos="flip-left">
                                <img src={IMG2004} style={{ borderRadius: '10px' }} alt="error" width="320px" height="320px" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AwardComp